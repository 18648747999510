import { API } from "../../constants";
import queryString from "querystring";
import store from "../../store";
import { logOut } from "../../store/slices/userInfo";
import { setNotificationData } from "../../store/slices/notifications";

const { getState, dispatch } = store;

class ApiHandler {
  domain;
  prefix;
  header;

  constructor(prefix, header) {
    this.domain = API;
    this.prefix = prefix;
    this.header = header;
  }

  createHeader() {
    const token = getState()?.userInfo?.model?.token;
    return {
      "Content-Type": "application/json",
      token,
    };
  }

  errorHandle(response) {
    if (response.ok) {
      const contentType = response.headers.get("content-type");
      if (contentType && ~contentType.indexOf("application/json")) {
        return response.json();
      } else {
        return response.text();
      }
    } else {
      const { status } = response;
      if (status === 401) {
        dispatch(logOut());
      } else {
        return response.json();
      }
    }
  }

  async notificationHandle(res) {
    if (res?.model?.transactionIsSucceeded || res?.model?.isSucceed) {
      dispatch(setNotificationData({ msg: "Action Success", type: "success" }));
    } else if (
      (res?.errorMessage || res?.errors) &&
      !["UserMustAuthenticateFisrt", "UserMustAuthenticate"].includes(
        res.errorMessage
      )
    ) {
      dispatch(
        setNotificationData({
          msg: res.errorMessage || res.title || "Unknown Error",
          type: "error",
        })
      );
    }
  }

  /**
   * @name get
   * @desc global get request
   * @param endPoint
   * @param queryFields
   * @returns {Promise<Response>}
   */
  get(endPoint, queryFields = {}) {
    const url = `${this.domain}/${this.prefix}/${endPoint}${
      queryFields ? "?" + queryString.stringify(queryFields) : ""
    }`;
    return fetch(url, {
      method: "GET",
      headers: this.createHeader(),
    }).then(async (res) => {
      const handledRes = await this.errorHandle(res);
      this.notificationHandle(handledRes);
      return handledRes;
    });
  }

  /**
   * @name post
   * @desc global post request
   * @param endPoint
   * @param body
   * @param queryFields
   * @param withNoNotification
   * @returns {Promise<Response>}
   */
  post(endPoint, body = {}, queryFields = {}, withNoNotification = false) {
    const url = `${this.domain}/${this.prefix}/${endPoint}${
      queryFields ? "?" + queryString.stringify(queryFields) : ""
    }`;

    return fetch(url, {
      method: "POST",
      headers: this.createHeader(),
      body: JSON.stringify(body),
    }).then(async (res) => {
      const handledRes = await this.errorHandle(res);
      if (!withNoNotification) {
        this.notificationHandle(handledRes);
      }
      return handledRes;
    });
  }

  /**
   * @name patch
   * @desc global patch request
   * @param endPoint
   * @param body
   * @param queryFields
   * @returns {Promise<Response>}
   */
  patch(endPoint, body = {}, queryFields = {}) {
    const url = `${this.domain}/${this.prefix}/${endPoint}${
      queryFields ? "?" + queryString.stringify(queryFields) : ""
    }`;

    return fetch(url, {
      method: "PATCH",
      headers: this.createHeader(),
      body: JSON.stringify(body),
    }).then(async (res) => {
      const handledRes = await this.errorHandle(res);
      this.notificationHandle(handledRes);

      return handledRes;
    });
  }

  /**
   * @name put
   * @desc global put request
   * @param endPoint
   * @param body
   * @param queryFields
   * @returns {Promise<Response>}
   */
  put(endPoint, body = {}, queryFields = {}) {
    const url = `${this.domain}/${this.prefix}/${endPoint}${
      queryFields ? "?" + queryString.stringify(queryFields) : ""
    }`;

    return fetch(url, {
      method: "PUT",
      headers: this.createHeader(),
      body: JSON.stringify(body),
    }).then(async (res) => {
      const handledRes = await this.errorHandle(res);
      this.notificationHandle(handledRes);

      return handledRes;
    });
  }

  /**
   * @name delete
   * @desc global delete request
   * @param endPoint
   * @param queryFields
   * @returns {Promise<Response>}
   */
  delete(endPoint, queryFields = {}) {
    const url = `${this.domain}/${this.prefix}/${endPoint}${
      queryFields ? "?" + queryString.stringify(queryFields) : ""
    }`;

    return fetch(url, {
      method: "DELETE",
      headers: this.createHeader(),
    }).then(async (res) => {
      const handledRes = await this.errorHandle(res);
      this.notificationHandle(handledRes);

      return handledRes;
    });
  }

  /**
   * @name download
   * @desc global download request
   * @param endPoint
   * @param body
   * @param queryFields
   * @param fileName
   * @param fileFormat
   * @returns {Promise<Blob | void>}
   */
  download(endPoint, body = {}, queryFields = {}, fileName = "", fileFormat = "csv") {
    const url = `${this.domain}/${this.prefix}/${
      endPoint ? endPoint + "/" : ""
    }${queryString.stringify(queryFields)}`;
    return fetch(url, {
      method: "POST",
      headers: this.createHeader(),
      body: JSON.stringify(body),
    })
      .then((response) => (response?.ok ? response.blob() : null))
      .then((blob) => {
        if (blob) {
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `${fileName}.${fileFormat}`);
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
        } else {
          dispatch(
            setNotificationData({ msg: "Unknown Error", type: "error" })
          );
        }
      })
      .catch((error) => console.error(error));
  }
}

export default ApiHandler;
