import { DEFAULT_PAGE } from "../../constants";
import { shallowEqual, useSelector } from "react-redux";

export function getAppData({ userInfo }) {
  const { token, passExpired } = userInfo?.model ?? {};

  return {
    token,
    passExpired,
  };
}

export function getCustomFiltersData(state) {
  const { ordersData, paginationsData, pageTableData } = state;
  const { isLastPage, dontSendRequest } = paginationsData;

  return {
    isLastPage,
    dontSendRequest,
    loader: pageTableData.loader,
    orders: ordersData?.orders ?? {},
    refetchPageData: pageTableData.refetchPageData,
    currentComponentUpdate: pageTableData.currentComponentUpdate,
  };
}

export function getPaginationData(state, dataKey) {
  const { pageTableData, paginationsData, viewData } = state;
  const { loader, data } = pageTableData;
  const { model } = data?.[dataKey] ?? {};
  const { existingPageCount, lastPageNumber, requestedPageNumber } =
    model ?? {};
  const { isLastPage, pageSize } = paginationsData;

  return {
    page: lastPageNumber ? lastPageNumber : requestedPageNumber ?? DEFAULT_PAGE,
    existingPageCount: existingPageCount ?? null,
    updateKey: viewData.updateKey,
    lastPageNumber,
    isLastPage,
    pageSize,
    loader,
  };
}

export function getSecondaryPaginationData(state) {
  const { secondaryPageData, paginationsData, viewData } = state;
  const { isLastPage, pageSize } = paginationsData;
  const { loader, data } = secondaryPageData;
  const { requestedPageNumber, listModel } = data?.tours?.model ?? {};
  const { existingPageCount, lastPageNumber } = listModel ?? {};

  return {
    page: lastPageNumber ? lastPageNumber : requestedPageNumber ?? DEFAULT_PAGE,
    existingPageCount: existingPageCount ?? 0,
    updateKey: viewData.updateKey,
    requestedPageNumber,
    isLastPage,
    pageSize,
    loader,
  };
}

export function getTournamentPlayersPagination(state) {
  const { secondaryPageData, paginationsData, viewData } = state;
  const { isLastPage, pageSize } = paginationsData;
  const { loader, data } = secondaryPageData;
  const { requestedPageNumber, tournamentPlayersModel } =
    data?.players?.model ?? {};
  const { existingPageCount, lastPageNumber } = tournamentPlayersModel ?? {};

  return {
    loader,
    pageSize,
    isLastPage,
    requestedPageNumber,
    updateKey: viewData.updateKey,
    existingPageCount: existingPageCount ?? 0,
    page: lastPageNumber ? lastPageNumber : requestedPageNumber ?? DEFAULT_PAGE,
  };
}

export function getTournamentChangesPagination(state) {
  const { secondaryPageData, paginationsData, viewData } = state;
  const { isLastPage, pageSize } = paginationsData;
  const { loader, data } = secondaryPageData;
  const { requestedPageNumber, gamesDataChangeModel } =
    data?.changes?.model ?? {};
  const { existingPageCount, lastPageNumber } = gamesDataChangeModel ?? {};

  return {
    isLastPage,
    pageSize,
    loader,
    requestedPageNumber,
    updateKey: viewData.updateKey,
    existingPageCount: existingPageCount ?? 0,
    page: lastPageNumber ? lastPageNumber : requestedPageNumber ?? DEFAULT_PAGE,
  };
}

export function getGameListData({
  filtersData,
  pageTableData,
  paginationsData,
  viewData,
}) {
  const { networkPartners, gameFinishTypes, gameKinds } =
    filtersData?.model ?? {};

  const {
    data,
    loader,
    refetchPageData,
    singleDataHistoryUrl,
    tableColumnsFiltered,
  } = pageTableData;

  return {
    loader,
    gameKinds,
    refetchPageData,
    networkPartners,
    gameFinishTypes,
    tableColumnsFiltered,
    updateKey: viewData.updateKey,
    list: data?.gameList?.model?.list || [],
    historyPageSrc: singleDataHistoryUrl?.model || null,
    ...paginationsData,
  };
}

export function getCreateGeneratorData({pageTableData, secondaryPageData, filtersData, popupInfo}) {
  const {loader} = pageTableData;
  const {doActionAfterPopupClose} = popupInfo;
  const {additionalData} = secondaryPageData;

  return {
    loader,
    ...filtersData?.secondModel,
    doActionAfterPopupClose,
    generatorDetails: additionalData?.model
  }
}

export function getTournamentGeneratorData( {
  filtersData,
  paginationsData,
  pageTableData,
  userInfo,
  viewData,
}) {
  const {
    data,
    loader,
    refetchPageData,
    singleDataHistoryUrl,
    tableColumnsFiltered,
  } = pageTableData;
  const { View, Add, Edit } = userInfo?.model?.userPermissions?.Generator ?? {};

  return {
    ...filtersData?.model,
    ...paginationsData,
    loader,
    refetchPageData,
    tableColumnsFiltered,
    updateKey: viewData.updateKey,
    isCurrency: viewData?.isCurrency,
    permissionToEdit: Edit,
    permissionToView: View,
    permissionToAdd: Add,
    list: data?.generators?.model?.list,
    historyPageSrc: singleDataHistoryUrl?.model || null,
  };
}

export function getTournamentListData({
  filtersData,
  paginationsData,
  pageTableData,
  userInfo,
  viewData,
}) {
  const {
    data,
    loader,
    refetchPageData,
    singleDataHistoryUrl,
    tableColumnsFiltered,
  } = pageTableData;
  const { Edit, Delete } = userInfo?.model?.userPermissions?.Tournament ?? {};

  return {
    ...filtersData?.model,
    ...paginationsData,
    loader,
    refetchPageData,
    tableColumnsFiltered,
    updateKey: viewData.updateKey,
    permissionToEdit: Edit ?? false,
    isCurrency: viewData?.isCurrency,
    permissionToDelete: Delete ?? false,
    list: data?.tournaments?.model?.list,
    historyPageSrc: singleDataHistoryUrl?.model || null,
  };
}

export function getDashboardGraphsData(state) {
  const { filtersData, pageTableData, viewData } = state;
  const { data, loader, refetchPageData } = pageTableData;
  const { model } = data.dashboardGraphs ?? {};

  return {
    ...filtersData.model,
    ...model,
    updateKey: viewData.updateKey,
    refetchPageData,
    loader,
  };
}

export function getDashboardGraphsTableData(state) {
  const { additionalDataOne, additionalDataTwo } = state.pageTableData;

  return {
    overallStatistics: additionalDataOne?.model || [],
    topWinners: additionalDataTwo?.model || [],
  };
}

export function getCommonReportData(state, key) {
  const {
    filtersData,
    paginationsData,
    pageTableData,
    userInfo,
    viewData,
  } = state;
  const { data, loader, refetchPageData } = pageTableData;

  return {
    ...filtersData.model,
    ...paginationsData,
    loader,
    refetchPageData,
    list: data[key]?.model?.list,
    updateKey: viewData.updateKey,
    permissionToExport:
      userInfo.model?.userPermissions?.BetsReport?.Export ?? false,
  };
}

export function getGamesReportData({ pageTableData, userInfo, viewData }) {
  const { data, loader, refetchPageData } = pageTableData;

  return {
    loader,
    refetchPageData,
    updateKey: viewData.updateKey,
    list: data?.gamesReport?.model?.list,
    permissionToExport:
      userInfo.model?.userPermissions?.GamesReport?.Export ?? false,
  };
}

export function getTournamentToursData({
  pageTableData,
  secondaryPageData,
  paginationsData,
  viewData,
}) {
  const { data, loader } = secondaryPageData ?? {};
  const { list } = data?.tours?.model?.listModel ?? {};
  const { additionalDataOne, additionalDataTwo } = pageTableData;

  return {
    tournamentTours: additionalDataOne ?? [],
    selectedTour: additionalDataTwo,
    updateKey: viewData.updateKey,
    loader,
    list,
    ...paginationsData,
  };
}

export function getTournamentChangesData({
  viewData,
  pageTableData,
  paginationsData,
  secondaryPageData,
}) {
  const { list } =
    secondaryPageData?.data?.changes?.model?.gamesDataChangeModel ?? {};
  const { isCurrency } = viewData ?? {};

  return {
    tournamentTours: pageTableData.additionalDataOne ?? [],
    loader: secondaryPageData.loader,
    ...paginationsData,
    list: list ?? [],
    isCurrency,
  };
}

export function getTournamentDetailsData({ secondaryPageData }) {
  const model = secondaryPageData?.data?.details?.model ?? {};

  return {
    loader: secondaryPageData.loader,
    model,
  };
}

export function getTournamentViewGameData(state) {
  const { popupInfo, pageTableData } = state;
  const { info } = popupInfo;
  const { model } = pageTableData.singleDataHistoryUrl ?? {};

  return {
    ...info,
    singleDataHistoryUrl: model,
  };
}

export function GetTournamentPlayersInfo(state) {
  const {
    secondaryPageData,
    paginationsData,
    pageTableData,
    userInfo,
    viewData,
  } = state;
  const { additionalData, data, loader } = secondaryPageData ?? {};
  const { model } = data?.players ?? {};
  const { tournamentPlayersModel } = model ?? {};
  const { list } = tournamentPlayersModel ?? {};
  const { Edit } = userInfo?.model?.userPermissions?.Tournament ?? {};

  return {
    playerStatuses: pageTableData.additionalDataTwo ?? [],
    ...additionalData,
    ...paginationsData,
    list,
    loader,
    updateKey: viewData.updateKey,
    currentComponentUpdate: pageTableData.currentComponentUpdate,
    permissionToEdit: Edit ?? false,
  };
}

export function GetNetworksList(state) {
  const { paginationsData, pageTableData, userInfo } = state;
  const { data, loader, refetchPageData } = pageTableData;
  const { model } = data?.networks ?? {};
  const { Add, Edit } = userInfo?.model?.userPermissions?.Network ?? {};

  return {
    ...paginationsData,
    loader,
    refetchPageData,
    list: model?.list ?? [],
    permissionToAdd: Add ?? false,
    permissionToEdit: Edit ?? false,
  };
}

export function GetCurrencyList(state) {
  const { paginationsData, pageTableData, viewData } = state;
  const { data, loader, refetchPageData } = pageTableData;
  const { model } = data?.currencies ?? {};

  return {
    ...paginationsData,
    loader,
    refetchPageData,
    list: model?.currencies ?? [],
    updateKey: viewData.updateKey,
  };
}

export function selectCurrencyPaginationData({
  pageTableData,
  paginationsData,
  viewData,
}) {
  const { loader, data } = pageTableData;
  const { isLastPage, pageSize } = paginationsData;
  const { dataListModel, requestedPageNumber } = data?.currencies?.model ?? {};
  const { existingPageCount, lastPageNumber } = dataListModel ?? {};

  return {
    loader,
    pageSize,
    isLastPage,
    requestedPageNumber,
    updateKey: viewData.updateKey,
    existingPageCount: existingPageCount ?? 0,
    page: lastPageNumber ? lastPageNumber : requestedPageNumber ?? DEFAULT_PAGE,
  };
}

export function GetAddNetworkData(state) {
  const { filtersData, pageTableData, viewData } = state;
  const { partners, gameKinds, currencies } = filtersData?.model ?? {};
  const { transactionIsSucceeded } = pageTableData?.additionalDataTwo ?? {};

  return {
    partners,
    gameKinds,
    currencies,
    transactionIsSucceeded,
    updateKey: viewData.updateKey,
  };
}

export function GetEditNetworkData(state) {
  const { filtersData, pageTableData, viewData } = state;
  const { additionalDataOne, loader } = pageTableData ?? {};
  const { model } = additionalDataOne ?? {};
  const { partners, gameKinds, currencies } = filtersData?.model ?? {};
  const { transactionIsSucceeded } = pageTableData?.additionalDataTwo ?? {};

  return {
    ...model,
    loader,
    gameKinds,
    currencies,
    transactionIsSucceeded,
    partnersFromFilter: partners,
    updateKey: viewData.updateKey,
  };
}

export function GetPartnersListData(state) {
  const {
    pageTableData,
    paginationsData,
    filtersData,
    userInfo,
    viewData,
  } = state;
  const { page, pageSize } = paginationsData;
  const { loader, data, refetchPageData } = pageTableData;
  const { model } = data?.partnersList ?? {};
  const { Network, Partner } = userInfo?.model?.userPermissions ?? {};
  const { Add, Edit } = Partner;

  return {
    page,
    loader,
    pageSize,
    refetchPageData,
    list: model?.list ?? [],
    updateKey: viewData.updateKey,
    permissionToAdd: Add ?? false,
    permissionToEdit: Edit ?? false,
    networks: filtersData?.model ?? [],
    permissionToViewNetwork: Network?.View ?? false,
  };
}

export function GetAddEditPartnerData(state) {
  const { pageTableData, viewData } = state;
  const { additionalDataTwo, additionalDataOne } = pageTableData;

  return {
    updateKey: viewData.updateKey,
    loader: pageTableData?.loader,
    currentComponentUpdate: pageTableData?.currentComponentUpdate,
    ...(additionalDataTwo ?? {}),
    ...(additionalDataOne ?? {}),
  };
}

export function GetPrizeDistributionData({ secondaryPageData }) {
  const { model } = secondaryPageData.data?.prizes ?? {};

  return {
    loader: secondaryPageData.loader,
    list: model ?? [],
  };
}

export function GetBIData(state) {
  const { pageTableData, filtersData, viewData } = state;
  const { partnerList, gameKindList } = filtersData.model ?? {};
  const {
    areFiltersOpened,
    loader,
    data,
    additionalDataOne,
    refetchPageData,
  } = pageTableData;

  return {
    loader,
    refetchPageData,
    areFiltersOpened,
    filterGames: gameKindList,
    filterPartners: partnerList,
    updateKey: viewData.updateKey,
    allColumns: additionalDataOne ?? [],
    infoListByDay: data?.dashboardByDay || [],
    infoListByMonth: data?.dashboardByMonth || [],
  };
}

export function GetBITableData(state) {
  const { areFiltersOpened, data, additionalDataOne } = state.pageTableData;

  return {
    areFiltersOpened,
    infoList: data ?? [],
    allColumns: additionalDataOne ?? [],
  };
}

export function GetAddNewPlayerPopupInfo(state) {
  return state.popupInfo;
}

export function selectAddNewTournamentPlayerData(state) {
  const { popupInfo } = state;

  return {
    ...popupInfo.info,
  };
}

export function selectTournamentPlayersIDListData (state) {
  return {
    tournamentId: state.popupInfo.info.tournamentId,
    playerIds: state.secondaryPageData?.data?.players?.model?.tournamentPlayersModel?.list || []
  };
}

export function useShallowSelector(selector) {
  return useSelector(selector, shallowEqual);
}

// ********** Jackpot List **********

export function selectJackpotListData(state) {
  const { filtersData, pageTableData, paginationsData, viewData } = state;
  const { data, loader, refetchPageData } = pageTableData;
  const { page, pageSize } = paginationsData;

  return {
    ...filtersData?.model,
    page,
    loader,
    pageSize,
    refetchPageData,
    updateKey: viewData.updateKey,
    list: data?.jackpot?.model?.jackpotModelList?.list ?? [],
  };
}

export function selectJackpotListPaginationData(state) {
  const { pageTableData, paginationsData, viewData } = state;
  const { isLastPage, pageSize } = paginationsData;
  const { loader, data } = pageTableData;
  const { jackpotModelList, requestedPageNumber } = data?.jackpot?.model ?? {};
  const { existingPageCount, lastPageNumber } = jackpotModelList ?? {};

  return {
    loader,
    pageSize,
    isLastPage,
    requestedPageNumber,
    updateKey: viewData.updateKey,
    existingPageCount: existingPageCount ?? 0,
    page: lastPageNumber ? lastPageNumber : requestedPageNumber ?? DEFAULT_PAGE,
  };
}

// ********** Lucky Spin **********

export function selectLuckySpinData(state) {
  const { filtersData, pageTableData, paginationsData, viewData } = state;
  const { data, loader, refetchPageData } = pageTableData;
  const { page, pageSize } = paginationsData;

  return {
    ...filtersData?.model,
    list: data?.luckySpin?.model?.takeRiskModelList?.list ?? [],
    updateKey: viewData.updateKey,
    refetchPageData,
    pageSize,
    loader,
    page,
  };
}

export function selectLuckySpinPaginationData(state) {
  const { pageTableData, paginationsData, viewData } = state;
  const { isLastPage, pageSize } = paginationsData;
  const { loader, data } = pageTableData;
  const { takeRiskModelList, requestedPageNumber } =
    data?.luckySpin?.model ?? {};
  const { existingPageCount, lastPageNumber } = takeRiskModelList ?? {};

  return {
    loader,
    pageSize,
    isLastPage,
    requestedPageNumber,
    updateKey: viewData.updateKey,
    existingPageCount: existingPageCount ?? 0,
    page: lastPageNumber ? lastPageNumber : requestedPageNumber ?? DEFAULT_PAGE,
  };
}

// ********** Rake Race **********

export function selectRakeRaceData(state) {
  const {
    paginationsData,
    pageTableData,
    filtersData,
    userInfo,
    viewData,
  } = state;
  const { data, loader, refetchPageData } = pageTableData;
  const { page, pageSize } = paginationsData;
  const { Add, Edit, Delete } =
    userInfo?.model?.userPermissions?.RakeRace ?? {};
  const { isCurrency, updateKey } = viewData ?? {};

  return {
    ...filtersData?.model,
    page,
    loader,
    pageSize,
    updateKey,
    isCurrency,
    refetchPageData,
    permissionToAdd: Add ?? false,
    permissionToEdit: Edit ?? false,
    permissionToDelete: Delete ?? false,
    list: data?.rakeRace?.model?.rakeRaceList?.list ?? [],
  };
}

export function selectRakeRacePaginationData(state) {
  const { pageTableData, paginationsData, viewData } = state;
  const { isLastPage, pageSize } = paginationsData;
  const { loader, data } = pageTableData;
  const { rakeRaceList, requestedPageNumber } = data?.rakeRace?.model ?? {};
  const { existingPageCount, lastPageNumber } = rakeRaceList ?? {};

  return {
    loader,
    pageSize,
    isLastPage,
    requestedPageNumber,
    updateKey: viewData.updateKey,
    existingPageCount: existingPageCount ?? 0,
    page: lastPageNumber ? lastPageNumber : requestedPageNumber ?? DEFAULT_PAGE,
  };
}

export function selectViewRakeRaceData(state) {
  const {
    name,
    games,
    status,
    partners,
    gameTypes,
    bannerUrl,
    networkId,
    prizeFund,
    minPoints,
    betRanges,
    startDate,
    finishDate,
    prizeDistribution,
  } = state.pageTableData.additionalDataOne?.model ?? {};

  return {
    name,
    status,
    gameTypes,
    networkId,
    prizeFund,
    bannerUrl,
    minPoints,
    betRanges,
    startDate,
    finishDate,
    prizeDistribution,
    gameKindIds: games,
    partnerIds: partners,
  };
}

export function selectChangePassData(state) {
  const { changeForgottenPass, changePassTokenIsValid, errorMessage, model } =
    state?.userInfo ?? {};

  const { userId, token, passExpired } = model ?? {};
  return {
    userId,
    passExpired,
    errorMessage,
    expireToken: token,
    changePassTokenIsValid,
    model: changeForgottenPass?.model ?? false,
  };
}

export function selectSetPasswordData({ userInfo, pageTableData }) {
  const { setPassword, setPasswordTokenIsValid, errorMessage } = userInfo ?? {};
  const { loader } = pageTableData ?? {};

  return {
    loader,
    errorMessage,
    tokenIsValid: setPasswordTokenIsValid,
    model: setPassword?.model ?? false,
  };
}

export function sideBarData(state) {
  const { model } = state?.userInfo ?? {};

  return {
    userPermissions: model?.userPermissions,
  };
}

// ********** User Management/Users **********

export function selectUsersData(state) {
  const {
    paginationsData,
    pageTableData,
    filtersData,
    userInfo,
    viewData,
  } = state;
  const { data, loader, refetchPageData } = pageTableData ?? {};
  const { model } = data?.users ?? {};

  const { users } = model ?? {};
  const { Add, Edit } = userInfo?.model?.userPermissions?.User ?? {};

  return {
    ...(filtersData?.model ?? {}),
    ...paginationsData,
    updateKey: viewData.updateKey,
    list: users ?? [],
    refetchPageData,
    loader,
    Edit,
    Add,
  };
}

export function selectUsersPaginationData(state) {
  const { pageTableData, paginationsData, viewData } = state;
  const { isLastPage, pageSize } = paginationsData;
  const { loader, data } = pageTableData;
  const { existingPageCount, lastPageNumber, requestedPageNumber } =
    data?.users?.model ?? {};

  return {
    loader,
    pageSize,
    isLastPage,
    requestedPageNumber,
    updateKey: viewData.updateKey,
    existingPageCount: existingPageCount ?? 0,
    page: lastPageNumber ? lastPageNumber : requestedPageNumber ?? DEFAULT_PAGE,
  };
}

export function selectUsersActionsData(state) {
  const { userInfo, pageTableData, viewData } = state;
  const {
    userPartners,
    userPermissions,
    isHaveAllPermissions,
    isHaveAllPartnersPermission,
  } = userInfo?.model ?? {};

  return {
    userPartners,
    userPermissions,
    isHaveAllPermissions,
    loader: pageTableData?.loader,
    updateKey: viewData.updateKey,
    backToParent: pageTableData?.backToParent,
    permissionsForAllPartners: isHaveAllPartnersPermission,
  };
}

export function selectBackofficeData(state) {
  const { userInfo } = state;

  return {
    token: userInfo?.model?.token,
    verificationToken: userInfo?.model?.verificationToken,
    errorAuthMessage: userInfo?.errorAuthMessage,
    userPermissions: userInfo?.model?.userPermissions,
  };
}

export function selectQrVerifyData(state) {
  const { userInfo } = state;
  const verifyError = userInfo?.verifyError;
  const { qrCodeImageUrl, manualEntrySetupCode } = userInfo?.model || {};

  return {
    verifyError,
    qrCodeImageUrl,
    manualEntrySetupCode,
  };
}

export function selectPartnerConfigurationData({
  pageTableData,
  paginationsData,
  filtersData,
  userInfo,
  viewData,
}) {
  const { Configuration } = userInfo?.model?.userPermissions ?? {};
  const { data, loader, refetchPageData } = pageTableData;
  const { page, pageSize, isLastPage } = paginationsData;
  const { Add, Edit, View } = Configuration ?? {};
  const { model } = data?.partnerData ?? {};

  return {
    page,
    loader,
    pageSize,
    isLastPage,
    refetchPageData,
    updateKey: viewData.updateKey,
    permissionToAdd: Add ?? false,
    permissionToEdit: Edit ?? false,
    list: model?.dataListModel?.data ?? [],
    permissionToViewPartner: View ?? false,
    partners: filtersData?.model?.partners ?? [],
  };
}

export function selectPartnerConfigurationPaginationData({
  pageTableData,
  paginationsData,
  viewData,
}) {
  const { loader, data } = pageTableData;
  const { isLastPage, pageSize } = paginationsData;
  const { dataListModel, requestedPageNumber } = data?.partnerData?.model ?? {};
  const { existingPageCount, lastPageNumber } = dataListModel ?? {};

  return {
    loader,
    pageSize,
    isLastPage,
    requestedPageNumber,
    updateKey: viewData.updateKey,
    existingPageCount: existingPageCount ?? 0,
    page: lastPageNumber ? lastPageNumber : requestedPageNumber ?? DEFAULT_PAGE,
  };
}

export function selectHeaderData({
  viewData,
  userInfo,
  pageTableData,
  secondaryPageData,
}) {
  const { firstName, lastName } = userInfo?.model ?? {};
  const { loader, disabled } = pageTableData;
  const { switcherDisabled, isCurrency } = viewData;

  return {
    lastName,
    firstName,
    isCurrency,
    switcherDisabled,
    loader: loader || disabled || secondaryPageData?.loader,
  };
}

export function selectHomePageData({ notificationData }) {
  const { msg, type, description } = notificationData;
  return { msg, type, description };
}

export function selectUserLogsData({
  pageTableData,
  paginationsData,
  viewData,
}) {
  const { logs } = pageTableData.data?.logs?.model?.logListModel ?? {};
  const { refetchPageData } = pageTableData;

  return {
    ...paginationsData,
    refetchPageData,
    list: logs ?? [],
    loader: pageTableData?.loader,
    updateKey: viewData.updateKey,
    requestKey: pageTableData?.data?.requestKey,
  };
}

export function selectUserLogsPaginationData({
  pageTableData,
  paginationsData,
  viewData,
}) {
  const { loader, data } = pageTableData;
  const { isLastPage, pageSize } = paginationsData;
  const { logListModel, requestedPageNumber } = data?.logs?.model ?? {};
  const { existingPageCount, lastPageNumber } = logListModel ?? {};

  return {
    loader,
    pageSize,
    isLastPage,
    requestedPageNumber,
    updateKey: viewData.updateKey,
    existingPageCount: existingPageCount ?? 0,
    page: lastPageNumber ? lastPageNumber : requestedPageNumber ?? DEFAULT_PAGE,
  };
}

export function selectUserActionsPopupData({ popupInfo, pageTableData }) {
  const { body, popupType, editingUserId } = popupInfo?.info ?? {};
  const { loader } = pageTableData ?? {};

  return {
    body,
    loader,
    popupType,
    editingUserId,
  };
}

export function selectActivateUserPopupData({ popupInfo, pageTableData }) {
  const { name, userId, statusId } = popupInfo?.info ?? {};
  const { loader } = pageTableData ?? {};

  return {
    name,
    userId,
    loader,
    statusId,
  };
}

export function selectQrResetPopupData({ popupInfo, pageTableData }) {
  const { userId } = popupInfo?.info ?? {};
  const { loader } = pageTableData ?? {};

  return { userId, loader };
}

export function selectDeletePlayerData(state) {
  return state.popupInfo.info;
}

export function selectDeleteTournamentData(state) {
  const { popupInfo, viewData } = state;
  const { info, filters } = popupInfo;

  return {
    tournamentId: info,
    filters: filters?.model || [],
    updateKey: viewData.updateKey,
  };
}

export function selectDeleteRakeRaceData({ popupInfo, pageTableData }) {
  const { rakeRaceId } = popupInfo.info;
  const { loader } = pageTableData;

  return {
    loader,
    rakeRaceId,
  };
}

export function selectPlayersListData({
  pageTableData,
  paginationsData,
  viewData,
  userInfo,
}) {
  const { Export } = userInfo?.model?.userPermissions?.RakeRace ?? {};
  const { page, pageSize, isLastPage } = paginationsData;
  const { data, loader } = pageTableData;

  return {
    page,
    loader,
    pageSize,
    isLastPage,
    permissionToExport: Export,
    updateKey: viewData.updateKey,
    list: data?.rakeRacePlayers?.model?.players?.playersPoint ?? [],
  };
}

export function selectResultsData({
  paginationsData,
  pageTableData,
  viewData,
  userInfo,
}) {
  const { Export } = userInfo?.model?.userPermissions?.RakeRace ?? {};
  const { page, pageSize, isLastPage } = paginationsData;
  const { data, loader } = pageTableData;

  const currency = data?.rakeRaceResults?.model?.playerRankings?.currency;
  return {
    page,
    loader,
    pageSize,
    isLastPage,
    permissionToExport: Export,
    updateKey: viewData.updateKey,
    currency,
    list:
      data?.rakeRaceResults?.model?.playerRankings?.playersRank.map((i) => ({
        ...i,
        fullWin: `${i.winAmount} ${currency} ${
          i.additionalPrize ? ` + ${i.additionalPrize}` : ""
        }`,
      })) ?? [],
  };
}

export function selectGameListPaginationData(state) {
  return getPaginationData(state, "gameList");
}

export function selectTournamentsPaginationData(state) {
  return getPaginationData(state, "tournaments");
}

export function selectTournamentGeneratorsPaginationData(state) {
  return getPaginationData(state, "generators");
}

export function selectResultsPaginationData({
  pageTableData,
  paginationsData,
  viewData,
}) {
  const { loader, data } = pageTableData;
  const { model } = data?.rakeRaceResults ?? {};
  const { requestedPageNumber, playerRankings } = model ?? {};
  const { existingPageCount, lastPageNumber } = playerRankings ?? {};
  const { isLastPage, pageSize } = paginationsData;

  return {
    page: lastPageNumber ? lastPageNumber : requestedPageNumber ?? DEFAULT_PAGE,
    existingPageCount: existingPageCount ?? null,
    updateKey: viewData.updateKey,
    lastPageNumber,
    isLastPage,
    pageSize,
    loader,
  };
}

export function selectPlayersPaginationData({
  pageTableData,
  paginationsData,
  viewData,
}) {
  const { loader, data } = pageTableData;
  const { model } = data?.rakeRacePlayers ?? {};
  const { requestedPageNumber, players } = model ?? {};
  const { existingPageCount, lastPageNumber } = players ?? {};
  const { isLastPage, pageSize } = paginationsData;

  return {
    page: lastPageNumber ? lastPageNumber : requestedPageNumber ?? DEFAULT_PAGE,
    existingPageCount: existingPageCount ?? null,
    updateKey: viewData.updateKey,
    lastPageNumber,
    isLastPage,
    pageSize,
    loader,
  };
}

export function selectGeneralFormData({ filtersData }) {
  const { model } = filtersData ?? {};
  const { networkPartners, gameKinds } = model ?? {};

  return {
    networkPartners,
    gameKinds,
  };
}

export function selectRakeRaceActionsPageData({
  filtersData,
  pageTableData,
  viewData,
}) {
  const { model } = filtersData ?? {};
  const { gameKinds } = model ?? {};
  const {
    name,
    games,
    status,
    partners,
    gameTypes,
    bannerUrl,
    networkId,
    prizeFund,
    minPoints,
    betRanges,
    startDate,
    finishDate,
    prizeDistribution,
  } = pageTableData.additionalDataOne?.model ?? {};

  return {
    name,
    status,
    gameTypes,
    networkId,
    prizeFund,
    bannerUrl,
    minPoints,
    betRanges,
    startDate,
    gameKinds,
    finishDate,
    prizeDistribution,
    gameKindIds: games,
    partnerIds: partners,
    isCurrency: viewData?.isCurrency,
  };
}

export function selectAddEditRakeRaceData({ popupInfo, pageTableData }) {
  const { values, actionType } = popupInfo.info ?? {};
  const { loader } = pageTableData;

  return {
    loader,
    values,
    actionType,
  };
}
