import {
  // ConfigurationIcon,
  LogsIcon,
  GamesIcon,
  SetupIcon,
  ReportsIcon,
  DashboardIcon,
  LuckySpinIcon,
  TournamentIcon,
  PromotionsIcon,
  JackpotListIcon,
  UserManagementIcon,
} from "../assets/icons";

export const ROUTES = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: DashboardIcon,
    children: [
      {
        key: "Graphs",
        name: "Graphs & Data",
        path: "/dashboard/graphs-BITable",
      },
      {
        key: "BIDailyAndMonthly",
        name: "BI Daily/Monthly",
        path: "/dashboard/business-intelligence",
      },
    ],
  },
  // {
  //     path: "/master-board",
  //     icon: SettingFilled,
  //     name: "Master Board",
  //     children: []
  // },
  // {
  {
    path: "/user-management",
    icon: UserManagementIcon,
    name: "User Management",
    children: [
      {
        key: "User",
        path: "/user-management/users",
        name: "Users",
      },
    ],
  },
  {
    name: "Logs",
    path: "/logs",
    icon: LogsIcon,
    children: [
      {
        name: "User Logs",
        key: "Logs",
        path: "/logs/user-logs",
      },
    ],
  },
  {
    path: "/reports",
    name: "Reports",
    icon: ReportsIcon,
    children: [
      {
        key: "BetsReport",
        name: "Bets Reports",
        path: "/reports/bets-reports",
      },
      {
        key: "TournamentsReport",
        name: "Tournaments Reports",
        path: "/reports/tournaments-reports",
      },
      {
        key: "GamesReport",
        name: "Game List Report",
        path: "/reports/game-list-report",
      },
      {
        key: "PlayerReport",
        name: "Players Reports",
        path: "/reports/players-reports",
      }
    ],
  },
  {
    key: "Games",
    path: "/game-list",
    name: "Game List",
    icon: GamesIcon,
  },
  {
    path: "/tournaments",
    icon: TournamentIcon,
    name: "Tournaments",
    children: [
      {
        key: "Tournament",
        path: "/tournaments/tournament-list",
        name: "Tournament List",
      },
    ],
  },
  {
    path: "/tournamentsNew",
    icon: TournamentIcon,
    name: "Tournaments New",
    children: [
      {
        key: "Generator",
        path: "/tournamentsNew/tournament-generator",
        name: "Tournament Generator",
      },
      {
        key: "Tournament",
        path: "/tournamentsNew/tournament-list",
        name: "Tournament List",
      }
    ],
  },
  {
    path: "/setup",
    icon: SetupIcon,
    name: "Setup",
    children: [
      {
        key: "Network",
        path: "/setup/networks-list",
        name: "Networks List",
      },
      {
        key: "Partner",
        path: "/setup/partners-list",
        name: "Partners List",
      },
      {
        key: "Configuration",
        path: "/setup/partner-configuration",
        name: "2FA Partner Configuration",
      },
      {
        key: "Currency",
        path: "/setup/currency",
        name: "Currency",
      },
    ],
  },
  {
    key: "Jackpot",
    path: "/jackpot-list",
    icon: JackpotListIcon,
    name: "Jackpot List",
  },
  {
    key: "TakeRisk",
    path: "/lucky-spin",
    icon: LuckySpinIcon,
    name: "Lucky Spin",
  },
  {
    path: "/promotions",
    icon: PromotionsIcon,
    name: "Promotions",
    children: [
      {
        key: "RakeRace",
        path: "/promotions/rake-race",
        name: "Rake Race",
      },
    ],
  },
];
