import React from "react";
import moment from "moment";
import { Button, Checkbox, Dropdown, Menu, Tooltip } from "antd";
import {
  ALL,
  ALL_TRUE,
  ALL_FALSE,
  DATE_TIME_FORMAT,
  WINNER_LOSER_COLOR,
  DATE_TIME_FORMAT_N3,
  DATE_TIME_FORMAT_N3_1,
  STATUS_STYLING_COLORS,
  ALL_PERMISSIONS_LENGTH,
  TABLE_CELL_INFO_MAX_LENGTH,
  USER_ACTIONS_PERMISSIONS_ICONS
} from "../constants";
import styles from "../pages/Tournaments/TournamentList/index.module.scss";
import store from "../store";
import { ROUTES } from "../routes";
import { CONDITIONS } from "../assets/icons";

export function decodeToken(token) {
  if (token) {
    const info = atob(token?.split(".")[1]);
    let obj;
    eval("obj=" + info);
    return obj;
  }
}

export function storeFilterIds(arrayFrom) {
  const arrayTo = [];
  for (let i = 0, len = arrayFrom.length; i < len; ++i) {
    arrayTo.push(arrayFrom[i].id);
  }
  return arrayTo;
}

export function arrowUpDownStyle(arg) {
  const style = {
    transform: "rotate(180deg)",
    transformOrigin: "center",
    transitionDuration: "0.15s"
  };
  if (arg) {
    style.transform = "rotate(0)";
  }
  return style;
}

export function deletePageFromTableData(tableData) {
  if (tableData.hasOwnProperty("pageNumber")) {
    delete tableData.pageNumber;
  }
  if (tableData.hasOwnProperty("pageSize")) {
    delete tableData.pageSize;
  }
}

export const getLocalIp = () =>
  new Promise((resolve, reject) => {
    window.RTCPeerConnection =
      window.RTCPeerConnection /*|| window.mozRTCPeerConnection*/ ||
      window.webkitRTCPeerConnection;
    if (typeof window.RTCPeerConnection == "undefined")
      return reject("WebRTC not supported by browser");
    const pc = new RTCPeerConnection();
    const ips = [];
    pc.createDataChannel("");
    pc.createOffer()
      .then(offer => pc.setLocalDescription(offer))
      .catch(err => reject(err));
    pc.onicecandidate = event => {
      if (!event || !event.candidate) {
        // All ICE candidates have been sent.
        if (ips.length == 0)
          return reject("WebRTC disabled or restricted by browser");
        return resolve(ips);
      }
      const parts = event.candidate.candidate.split(" ");
      const ip = parts[4];
      if (!ips.some(e => e == ip)) ips.push(ip);
    };
  });

export const nullToUndefined = arg => {
  if (arg === null) {
    return undefined;
  }
  return arg;
};

export const isNotEmpty = obj => {
  return Object.keys(obj).length > 0;
};

export function getPermissionGroupsVEColumn(
  selectAll,
  onChange,
  checkAllChildrenChecked,
  originalPermissions
) {
  return [
    {
      title: "Resources",
      dataIndex: "resources",
      key: "name"
    },
    {
      title: "View",
      dataIndex: "view",
      key: "id",
      render: function(text, record) {
        const checked =
          record.permissionName === "Parent"
            ? checkAllChildrenChecked(record.unitId)
            : record.view.checked;
        return (
          <div>
            <Checkbox
              defaultChecked={checked}
              checked={checked}
              onChange={e => {
                onChange(e.target.checked, record.id, "view");
              }}
              style={{
                pointerEvents:
                  originalPermissions &&
                  findCertainPermission(
                    originalPermissions,
                    record?.resources,
                    "view"
                  )
                    ? "all"
                    : "none"
              }}
            />
          </div>
        );
      }
    },
    {
      title: "Edit",
      dataIndex: "edit",
      key: "id",
      render: (text, record) => {
        const checked =
          record.permissionName === "Parent"
            ? checkAllChildrenChecked(record.unitId)
            : record.edit.checked;
        return (
          <div>
            <Checkbox
              defaultChecked={checked}
              checked={checked}
              onChange={e => {
                onChange(e.target.checked, record.id, "edit");
              }}
              style={{
                pointerEvents:
                  originalPermissions &&
                  findCertainPermission(
                    originalPermissions,
                    record?.resources,
                    "edit"
                  )
                    ? "all"
                    : "none"
              }}
            />
          </div>
        );
      }
    },
    {
      title: "Add",
      dataIndex: "add",
      key: "id",
      render: (text, record) => {
        const checked =
          record.permissionName === "Parent"
            ? checkAllChildrenChecked(record.unitId)
            : record.add.checked;
        return (
          <div>
            <Checkbox
              defaultChecked={checked}
              checked={checked}
              onChange={e => {
                onChange(e.target.checked, record.id, "add");
              }}
              style={{
                pointerEvents:
                  originalPermissions &&
                  findCertainPermission(
                    originalPermissions,
                    record?.resources,
                    "add"
                  )
                    ? "all"
                    : "none"
              }}
            />
          </div>
        );
      }
    },
    {
      title: "Export",
      dataIndex: "export",
      key: "id",
      render: (text, record) => {
        const checked =
          record.permissionName === "Parent"
            ? checkAllChildrenChecked(record.unitId)
            : record.export.checked;
        return (
          <div>
            <Checkbox
              defaultChecked={checked}
              checked={checked}
              onChange={e => {
                onChange(e.target.checked, record.id, "export");
              }}
              style={{
                pointerEvents:
                  originalPermissions &&
                  findCertainPermission(
                    originalPermissions,
                    record?.resources,
                    "export"
                  )
                    ? "all"
                    : "none"
              }}
            />
          </div>
        );
      }
    },
    {
      title: "Select All",
      key: "id",
      render: (text, record) => {
        const allChecked =
          record.permissionName === "Parent"
            ? checkAllChildrenChecked(record.unitId)
            : record.view.checked &&
              record.edit.checked &&
              record.add.checked &&
              record.export.checked;
        return (
          <div>
            <Checkbox
              defaultChecked={allChecked}
              checked={allChecked}
              onChange={e => {
                selectAll(record.id, record.unitId, e.target.checked);
              }}
              indeterminate={allChecked}
              style={{ pointerEvents: allChecked ? "all" : "none" }}
            />
          </div>
        );
      }
    }
  ];
}

export function processPermissionData(data) {
  const finalArray = [];

  function setPermissionSingleRowInfo(permissions) {
    const processed = {
      id: 0,
      groupId: -1,
      parentId: -1,
      unitId: -1,
      resources: "",
      permissionName: "",
      view: { checked: false },
      edit: { checked: false },
      add: { checked: false },
      export: { checked: false }
    };
    processed.id = permissions[0].id;
    processed.groupId = permissions[0].permissionGroupId;
    processed.parentId = permissions[0].parentId;
    processed.unitId = permissions[0].unitId;
    processed.resources = permissions[0].unitName;
    processed.permissionName = permissions[0].permissionName;
    for (let i = 0, len = permissions.length; i < len; ++i) {
      switch (permissions[i].permissionName) {
        case "view":
          processed.view = { ...permissions[i], checked: true };
          break;
        case "edit":
          processed.edit = { ...permissions[i], checked: true };
          break;
        case "add":
          processed.add = { ...permissions[i], checked: true };
          break;
        case "export":
          processed.export = { ...permissions[i], checked: true };
          break;
      }
    }
    finalArray.push(processed);
  }

  (function foo(data, isChild) {
    for (const key in data) {
      if (data.hasOwnProperty(key) && isNotEmpty(data[key])) {
        if (key === "permissions" || isChild) {
          setPermissionSingleRowInfo(data[key]);
        } else if (key === "childs") {
          foo(data[key], true);
        } else {
          foo(data[key]);
        }
      }
    }
  })(data);
  return finalArray;
}

export function filterUsersByPermissionGroupId(id, usersList) {
  return usersList.filter(user => user.permissionGroupId === id);
}

export function isActive(statusId) {
  if (statusId === 1) {
    return true;
  } else if (statusId === 2) {
    return false;
  }
}

export function showTotal(total, range, list) {
  if (list.length < 1) {
    return ``;
  }
  if (list.length === 1) {
    return `${range[0]} of ${total}`;
  }
  return `${range[0]}-${range[1]} of ${total}`;
}

export function idToName(ids, data) {
  const array = [];
  if (ids && data) {
    for (let i = 0, len = ids.length; i < len; ++i) {
      const name = data.find(item => item.id === ids[i])?.name;
      if (name) {
        array.push(name);
      }
    }
  }
  return array;
}

// export function transformToSelectData(BITable, selectAll) {
//     const selectData = [{ label: "Select All", value: "All" }];
//     for (let i = 0, len = BITable.length; i < len; ++i) {
//         if (typeof BITable[i] === "string") {
//             selectData.push({ label: BITable[i], value: BITable[i] });
//         } else {
//             selectData.push({ label: BITable[i].name, value: BITable[i].id });
//         }
//     }
//     if (!selectAll) {
//         selectData.shift();
//     }
//     return selectData;
// }
export function transformFilterDataForTreeSelect(
  filterData,
  columnsFilter = false
) {
  if (filterData) {
    const transformedData = [
      {
        title: ALL,
        value: ALL,
        key: ALL,
        children: []
      }
    ];
    for (let i = 0, len = filterData?.length; i < len; ++i) {
      const item = filterData[i] ?? {};
      const { name, id, title } = item;
      transformedData[0].children.push(
        columnsFilter
          ? { title, value: JSON.stringify({ ...item }) }
          : { title: name, value: id, key: id }
      );
    }

    return transformedData;
  }
  return [];
}

export function dataForCreateTreeSelect(data) {
  const returnValue = [
    {
      title: ALL,
      value: [],
      key: ALL,
      children: []
    }
  ];

  for (let i = 0, len = data?.length; i < len; ++i) {
    const { name, id } = data[i] ?? {};
    returnValue[0].value.push(id);
    returnValue[0].children.push({ title: name, value: id, key: id });
  }

  return data?.length ? returnValue : [];
}

export function partnersDropdown(array) {
  function setOverlayMenu() {
    if (array.length > 1) {
      return (
        <Menu>
          {array.map((item, index) => {
            if (index > 0) {
              return <Menu.Item key={index}>{item}</Menu.Item>;
            }
          })}
        </Menu>
      );
    }
    return <span style={{ display: "none" }} />;
  }

  if (array.length > 0) {
    return (
      <>
        <span>{array[0]}</span>
        {array.length > 1 ? (
          <Dropdown overlay={setOverlayMenu()}>
            <span style={{ cursor: "pointer" }}> +{array.length - 1}...</span>
          </Dropdown>
        ) : null}
      </>
    );
  }
  return <span style={{ display: "none" }} />;
}

export function viewOrEditButtons(disabled, okCallback, discardCallback) {
  if (disabled) {
    return (
      <Button type="primary" onClick={okCallback}>
        Ok
      </Button>
    );
  }
  return (
    <>
      <Button type="text" htmlType="reset" onClick={discardCallback}>
        Discard
      </Button>
      <Button type="primary" htmlType="submit">
        Save
      </Button>
    </>
  );
}

// function backProcessHelper(obj, allPermissions, isParent) {
//     const basePermission = {
//         id: 0,
//         permissionId: 0,
//         permissionGroupId: obj.groupId,
//         unitId: obj.unitId,
//         unitName: obj.name,
//         permissionName: "",
//         parentId: 0,
//         checked: true
//     };
//     const permissionsArray = [];
//     const view = obj.view;
//     const edit = obj.edit;
//     const add = obj.add;
//     const exp = obj.export;
//     if (isParent) {
//         permissionsArray.push({ ...basePermission, permissionName: "Parent" });
//     } else {
//         if (view.checked) {
//             if (view.hasOwnProperty("id")) {
//                 permissionsArray.push(view);
//             } else {
//                 const singlePerm = allPermissions.find(perm => perm.name === "view");
//                 permissionsArray.push({ ...basePermission, permissionId: singlePerm.id, permissionName: "view" });
//             }
//         }
//         if (edit.checked) {
//             if (edit.hasOwnProperty("id")) {
//                 permissionsArray.push(edit);
//             } else {
//                 const singlePerm = allPermissions.find(perm => perm.name === "edit");
//                 permissionsArray.push({ ...basePermission, permissionId: singlePerm.id, permissionName: "edit" });
//             }
//         }
//         if (add.checked) {
//             if (add.hasOwnProperty("id")) {
//                 permissionsArray.push(add);
//             } else {
//                 const singlePerm = allPermissions.find(perm => perm.name === "add");
//                 permissionsArray.push({ ...basePermission, permissionId: singlePerm.id, permissionName: "add" });
//             }
//         }
//         if (exp.checked) {
//             if (exp.hasOwnProperty("id")) {
//                 permissionsArray.push(exp);
//             } else {
//                 const singlePerm = allPermissions.find(perm => perm.name === "export");
//                 permissionsArray.push({ ...basePermission, permissionId: singlePerm.id, permissionName: "export" });
//             }
//         }
//     }
//     return permissionsArray;
// }

// export function backProcessPermissionData(dataToProcess, allPermissions) {
//     function setChildren(children) {
//         const childs = {};
//         for (let i = 0, len = children.length; i < len; ++i) {
//             childs[i] = backProcessHelper(children[i], allPermissions);
//         }
//         return childs;
//     }
//     const BITable = {};
//     for (let i = 0, len = dataToProcess.length; i < len; ++i) {
//         const dataItem = dataToProcess[i];
//         if (dataItem.parentId === 0) {
//             if (dataItem.permissionName === "Parent") {
//                 const children = dataToProcess.filter(child => child.parentId === dataItem.unitId);
//                 BITable[i] = {
//                     permissions: backProcessHelper(dataItem, allPermissions, true),
//                     childs: setChildren(children)
//                 };
//             } else {
//                 BITable[i] = { permissions: backProcessHelper(dataItem, allPermissions), childs: {} };
//             }
//         }
//     }
//     return BITable;
// }

export function setColumnsForDataTable(
  columns,
  columnsWithRender = null,
  actionColumn = null
) {
  const tableColumns = [];
  for (let i = 0, len = columns.length; i < len; ++i) {
    const column = columns[i];
    const { title, dataIndex, render, key } = column;
    if (columnsWithRender?.hasOwnProperty(title)) {
      tableColumns.push({
        title,
        dataIndex,
        render: columnsWithRender[title],
        key
      });
    } else {
      tableColumns.push({ title, dataIndex, render, key });
    }
  }
  if (actionColumn) {
    return [...tableColumns, ...actionColumn];
  }
  return tableColumns;
}

export function addUniqRowKey(dataToAdd, dataName) {
  if (dataToAdd?.length > 0) {
    for (let i = 0, len = dataToAdd.length; i < len; ++i) {
      dataToAdd[i].rowKey = dataName + i;
    }
  }
}

export function setDefaultDateRange(dateFormat = DATE_TIME_FORMAT) {
  const today = new Date();
  const yesterday = new Date(today.valueOf() - 1000 * 60 * 60 * 24);
  const rangeMinDay = new Date(today.valueOf() - 1000 * 60 * 60 * 24 * 90);
  const hour = today.getHours();
  const minute = today.getMinutes();

  return {
    started: moment(
      `${yesterday.getFullYear()}/${yesterday.getMonth() +
        1}/${yesterday.getDate()} ${hour}:${minute}`,
      dateFormat
    ),
    finished: moment(
      `${today.getFullYear()}/${today.getMonth() +
        1}/${today.getDate()} ${hour}:${minute}`,
      dateFormat
    ),
    minRangeDate: moment(
        `${rangeMinDay.getFullYear()}/${rangeMinDay.getMonth() +
        1}/${rangeMinDay.getDate()} ${hour}:${minute}`,
        dateFormat
    )
  };
}

export function setUserExpirationDefaultDate() {
  const day = new Date();
  day.setMonth(day.getMonth() + 3);

  return moment(day);
}

export function overallStatisticsRanges() {
  const today = new Date();
  // console.log(today.getMonth(), new Date(today.getFullYear(), 2, 0).getDate());
  const yesterday = new Date(today.valueOf() - 1000 * 60 * 60 * 24);
  const tomorrow = new Date(today.valueOf() + 1000 * 60 * 60 * 24);
  const pastMonthDay = new Date(today.valueOf() - 1000 * 60 * 60 * 24 * 30);
  const pastMonthDayAfter = new Date(
    tomorrow.valueOf() - 1000 * 60 * 60 * 24 * 30
  );
  today.setUTCHours(0, 0, 0, 0);
  const hour = today.getHours();
  const minute = today.getMinutes();
  return [
    {
      key: "Today",
      startDate: dayToMoment(today, DATE_TIME_FORMAT, hour, minute),
      endDate: dayToMoment(tomorrow, DATE_TIME_FORMAT, hour, minute)
    },
    {
      key: "Yesterday",
      startDate: dayToMoment(yesterday, DATE_TIME_FORMAT, hour, minute),
      endDate: dayToMoment(today, DATE_TIME_FORMAT, hour, minute)
    },
    {
      key: "Month to Date",
      startDate: dayToMoment(pastMonthDay, DATE_TIME_FORMAT, hour, minute),
      endDate: dayToMoment(tomorrow, DATE_TIME_FORMAT, hour, minute)
    },
    {
      key: "Same Period Last Month",
      startDate: dayToMoment(pastMonthDay, DATE_TIME_FORMAT, hour, minute),
      endDate: dayToMoment(pastMonthDayAfter, DATE_TIME_FORMAT, hour, minute)
    }
  ];
}

export function dayToMoment(day, format, hour = 0, minute = 0) {
  return moment(
    `${day.getFullYear()}/${day.getMonth() +
      1}/${day.getDate()} ${hour}:${minute}`,
    format
  );
}

export function stringToMoment(string, format = DATE_TIME_FORMAT) {
  if (string) {
    const date = new Date(string);
    const hour = date.getHours();
    const minutes = date.getMinutes();

    return dayToMoment(date, format, hour, minutes);
  }
}

export function parseDateToLocalAndFormat(date, format) {
  if (date && format) {
    return moment
      .utc(date)
      .local()
      .format(format);
  }
}

export function findCertainPermission(
  permissions,
  permissionGroupName,
  permissionName
) {
  const permGroup = permissions?.find(
    permission => permission.resources === permissionGroupName
  );
  if (permGroup) {
    return permGroup[permissionName]?.checked;
  }
}

export function renderChildPagesFromParent(
  childPageName,
  childPages,
  parentPage
) {
  if (childPageName && childPages.hasOwnProperty(childPageName)) {
    return childPages[childPageName];
  }
  return parentPage;
}

export function getCurrentDate() {
  const today = new Date();
  const dd = String(today.getDate()).padStart(2, "0");
  const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
  const yyyy = today.getFullYear();
  return yyyy + "-" + mm + "-" + dd;
}

export function backProcessPermissionDataForCreateRequest(
  permissionDataToProcess
) {
  const processedData = [];
  for (let i = 0, len = permissionDataToProcess.length; i < len; ++i) {
    const singlePermission = permissionDataToProcess[i];
    if (
      singlePermission.permissionName !== "Parent" &&
      singlePermission.parentId !== 0
    ) {
      if (singlePermission.view?.checked) {
        processedData.push({
          unitId: singlePermission.unitId,
          permissionId: 1
        });
      }
      if (singlePermission.edit?.checked) {
        processedData.push({
          unitId: singlePermission.unitId,
          permissionId: 3
        });
      }
      if (singlePermission.add?.checked) {
        processedData.push({
          unitId: singlePermission.unitId,
          permissionId: 2
        });
      }
      if (singlePermission.export?.checked) {
        processedData.push({
          unitId: singlePermission.unitId,
          permissionId: 4
        });
      }
    }
  }
  return processedData;
}

export function findPartnersBySelectedNetworks(
  selectedNetworks,
  networkPartners
) {
  if (selectedNetworks?.length && selectedNetworks.indexOf(ALL) < 0) {
    let partnersByNetworks = [];
    selectedNetworks.forEach(item => {
      const itemPartners = networkPartners?.find(i => i.network.id === item)
        ?.partners;
      itemPartners?.forEach(iPart => {
        if (!partnersByNetworks?.find(pByN => pByN.id === iPart.id)) {
          partnersByNetworks.push(iPart);
        }
      });
    });
    return partnersByNetworks;
  }
}

export function findPartnersBySelectedNetwork(
    selectedNetwork,
    networkPartners
) {
  if (selectedNetwork) {
    const returnValue = [];
    for(let i = 0; i < networkPartners?.length; ++i) {
      if (networkPartners[i].network.id === selectedNetwork) {
        returnValue.push(...networkPartners[i].partners)
      }
    }
    return returnValue;
  }
}

export function separatePartnersAndNetworks(networkPartners) {
  const networks = [];
  const partnersAll = [];

  networkPartners?.forEach(item => {
    const { network, partners } = item ?? {};
    network && networks.push(network);
    partners?.forEach(i => {
      if (!partnersAll.find(partner => partner.id === i.id)) {
        partnersAll.push(i);
      }
    });
  });

  return { networks, partnersAll };
}

export function findGameTypeBySelectedGameKind(selectedGames, gameKinds) {
  if (selectedGames?.length && selectedGames.indexOf(ALL) < 0) {
    let selectedGameTypes = [];
    selectedGames.forEach(item => {
      const found = gameKinds?.find(i => i.gameKind.id === item)?.gameTypes;
      if (found) {
        selectedGameTypes = [...selectedGameTypes, ...found];
      }
    });
    return selectedGameTypes;
  }
}

export function separateGameTypesAndKinds(data) {
  const gameKindsAll = [];
  const gameTypesAll = [];

  if (data?.length) {
    data.forEach(item => {
      const { gameKind, gameTypes } = item ?? {};
      gameKind && gameKindsAll.push(gameKind);
      gameTypes?.forEach(i => {
        if (!gameTypesAll.find(gameType => gameType.id === i.id)) {
          gameTypesAll.push(i);
        }
      });
    });
  }

  return { gameKindsAll, gameTypesAll };
}

export function renderStatus(customStatusColors) {
  return status => {
    if (status) {
      let outputStatus;
      if (status === "ToEnd") {
        outputStatus = "Finished";
      } else if (status === "Surrender") {
        outputStatus = "Surrendered";
      } else {
        outputStatus = status.split(/(?=[A-Z])/).join(" ");
      }
      const { circleBackground, backgroundColor } = (customStatusColors || STATUS_STYLING_COLORS)[status];

      return (
          <p className={styles.statusContainer} style={{ backgroundColor }}>
        <span
            className={styles.statusCircle}
            style={{ backgroundColor: circleBackground }}
        />
            <Tooltip title={outputStatus} color="rgba(0, 0, 0, 1)">
              <span className={styles.statusText}>{outputStatus}</span>
            </Tooltip>
          </p>
      );
    }
  }
}

export function formatNumber(infoToFormat, bringToFixed = false) {
  if (typeof infoToFormat !== "number") {
    infoToFormat = Number(infoToFormat);
  }

  const fixedInfo = infoToFormat?.toFixed(2);

  if (fixedInfo?.length > 6) {
    const array = fixedInfo.split(".");
    const integerPart = array[0];
    const fractionalPart = array[1];
    const intArray = integerPart.split("");
    const len = intArray.length;
    const newArray = [];
    let compare = 0;

    if (len > 3) {
      if (len % 3 === 1) {
        compare = 1;
      } else if (len % 3 === 2) {
        compare = 2;
      }
    } else {
      return bringToFixed ? array.join(".") : integerPart;
    }

    for (let i = len - 1; i >= 0; --i) {
      newArray.push(intArray[i]);
      if (i % 3 === compare) {
        newArray.push(" ");
      }
    }

    const formatted = newArray
      .reverse()
      .join("")
      .trim();

    return bringToFixed ? formatted + "." + fractionalPart : formatted;
  }

  return bringToFixed ? fixedInfo : fixedInfo.split(".")[0];
}

export function renderAdditionalInfoCells(
  data,
  cellWidth,
  cellBackground,
  titleColor,
  infoColor
) {
  return data?.map((dataItem, index) => {
    if (dataItem) {
      const {
        title,
        cellTotalInfo,
        separator,
        children,
        specificBackground,
        specificTitle,
        specificText
      } = dataItem;
      let infoText = "";
      if (cellTotalInfo?.length) {
        cellTotalInfo.forEach((cellInfoItem, infoIndex) => {
          const {
            info,
            preInfoText,
            postInfoText,
            bringToFixed
          } = cellInfoItem;
          const preInfoTextChecked = preInfoText ?? "";
          const postInfoTextChecked = postInfoText ?? "";
          let infoPart = "";
          if (info || info === 0) {
            if (typeof info === "number") {
              infoPart = `${preInfoTextChecked} ${formatNumber(
                info,
                bringToFixed
              )} ${postInfoTextChecked}`;
            } else {
              infoPart = `${preInfoTextChecked} ${info
                .split(/(?=[A-Z])/)
                .join(" ")} ${postInfoTextChecked}`;
            }
          } else {
            infoPart = `${preInfoTextChecked} -`;
          }
          infoText += `${infoPart} ${
            infoIndex < cellTotalInfo?.length - 1 && separator ? separator : ""
          }`;
        });
      }
      return (
        <div
          key={index}
          style={{
            backgroundColor: specificBackground ?? cellBackground,
            width: cellWidth,
            opacity: title ? "1" : "0"
          }}
        >
          <p style={{ color: specificTitle ?? titleColor }}>{title}</p>
          <h2 style={{ color: specificText ?? infoColor }}>{infoText}</h2>
          {children ?? null}
        </div>
      );
    }
  });
}

export function statusFilterHelper(statuses) {
  for (let i = 0, len = statuses.length; i < len; ++i) {
    if (statuses[i].name === "Surrender") {
      statuses[i].name = "Surrendered";
    } else if (statuses[i].name === "TimeOut") {
      statuses[i].name = "Time Out";
    } else if (statuses[i].name === "ToEnd") {
      statuses[i].name = "Finished";
    } else {
      statuses[i].name = statuses[i].name.split(/(?=[A-Z])/).join(" ");
    }
  }
}

export const getObjectWithSpecificKeyPrefix = (obj, prefix, keepValues = false) => {
  return Object.keys(obj)
      .filter(key => key.startsWith(prefix))
      .reduce((acc, key) => {
        acc[key] = keepValues ? obj[key] : undefined;
        return acc;
      }, {});
};

export function renderWithTooltip(info, customLength) {
  const length =
    typeof customLength === "number"
      ? customLength
      : TABLE_CELL_INFO_MAX_LENGTH;
  const infoToString = typeof info === "string" ? info : info?.toString();
  let infoToShow = "-";
  if (infoToString) {
    if (infoToString.length > length) {
      infoToShow = infoToString.substring(0, length - 4) + "...";
    } else {
      infoToShow = infoToString;
    }
  }

  return (
    <Tooltip title={infoToString} color="rgba(0, 0, 0, 1)">
      <span style={{ whiteSpace: "nowrap" }}>{infoToShow}</span>
    </Tooltip>
  );
}

export function renderDateWithTooltip(info) {
  return renderWithTooltip(parseDateToLocalAndFormat(info, DATE_TIME_FORMAT));
}

export function fromArrayToObject(array) {
  const length = array?.length;
  if (length) {
    let returnObject = {};
    for (let i = 0; i < length; ++i) {
      returnObject = { ...returnObject, ...array[i] };
    }
    return returnObject;
  }
}

export function renderConventions(data) {
  return data
    ? Object.entries(data)
        .filter(
          c =>
            Object.keys(CONDITIONS).includes(c[0]) &&
            (c[0] === "showToGuest"
              ? !c[1]
              : c[0] === "maxStakeDoubling"
              ? c[1] > 1
              : c[1])
        )
        .map(j => CONDITIONS[j[0]])
    : [];
}

export function renderPlayersIds(data, dataIndex) {
  if (data && dataIndex && data.hasOwnProperty(dataIndex)) {
    const playerNick = data[dataIndex];
    const winnerNick = data["winnerNickName"];
    const { winner, loser } = WINNER_LOSER_COLOR;
    return (
      <Tooltip title={playerNick} color="rgba(0, 0, 0, 1)">
        <span
          style={{
            color: winnerNick
              ? playerNick === winnerNick
                ? winner
                : loser
              : "inherit",
            whiteSpace: "nowrap"
          }}
        >
          {playerNick?.length > TABLE_CELL_INFO_MAX_LENGTH
            ? playerNick.substring(0, TABLE_CELL_INFO_MAX_LENGTH - 4) + "..."
            : playerNick}
        </span>
      </Tooltip>
    );
  }
}

export function fromRandomArrayToSelectOptions(
  array,
  labelProp,
  valueProp,
  preLabel = "",
  postLabel = ""
) {
  return array?.map(item => {
    return {
      label: `${preLabel} ${item[labelProp].toString() ?? ""} ${postLabel}`,
      value: item[valueProp] ?? null,
      disabled: item.disabled
    };
  });
}

export function getValidConventionsByDependencies(conventions = [], selectedScore, selectedConventions, setSelectedConventions, form) {
  const tmpConventions = JSON.parse(JSON.stringify(conventions));

  return tmpConventions?.map(i => {
    const {excludeScores, dependencies} = i;

    if (excludeScores?.includes(selectedScore)) {
      i.disabled = true;
      if (selectedConventions.includes(i.convention)) {
        const newSelectedConventions = selectedConventions.filter(selConv => selConv !== i.convention);
        setSelectedConventions(newSelectedConventions);
        form?.setFieldValue('convention', newSelectedConventions)
      }
    } else {
      dependencies?.forEach(dep => {
        if ((dep.isInverseLink === true && selectedConventions.includes(dep.id)) ||
            (dep.isInverseLink === false && !selectedConventions.includes(dep.id))) {
          tmpConventions.find(conv => conv.convention === i.convention).disabled = true;
          if (selectedConventions.includes(i.convention)) {
            const newSelectedConventions = selectedConventions.filter(j => j !== i.convention)

            setSelectedConventions(newSelectedConventions);
            form?.setFieldValue('convention', newSelectedConventions)
          }
        }
      })
    }

    return i;
  });
}

export function returnTableColumnsWithRightPositions(
  filteredColumns,
  originalColumns
) {
  if (filteredColumns?.length) {
    let returnValue = [];
    for (let i = 0, len = originalColumns.length; i < len; ++i) {
      const column = originalColumns[i];
      const foundColumn = filteredColumns.find(
        item => item.title === column.title
      );
      if (foundColumn) {
        returnValue.push(foundColumn);
      }
    }
    return returnValue;
  }
  return originalColumns;
}

export function getCurrentTimeZoneOffset() {
  const offset = new Date().getTimezoneOffset();
  return offset / -60;
}

export function filterIds(data) {
  const returnValue = [];

  for (let i = 0, len = data?.length; i < len; ++i) {
    returnValue.push(data[i].id);
  }
  return returnValue;
}

export function filterNames(data) {
  const returnValue = [];

  for (let i = 0, len = data?.length; i < len; ++i) {
    returnValue.push(data[i].name);
  }
  return returnValue;
}

export function addUniqKeyToTableData(data, key1, key2) {
  const copy = JSON.parse(JSON.stringify(data));
  for (let i = 0, len = copy?.length; i < len; ++i) {
    const dataItem = copy[i];
    dataItem.uniqKey = dataItem[key1] + dataItem[key2];
  }
  return copy;
}

// function transformHelper(partnerList, gameName) {
//     function innerHelper(betStatisticList) {
//         const reTurnValue = {};
//         for (let i = 0, len = betStatisticList?.length; i < len; ++i) {
//             const { betCount, betSum, date, uniquePlayerCount } = betStatisticList[i];
//             const formattedDate = parseDateToLocalAndFormat(
//                 date,
//                 isDataByDay ? DATE_TIME_FORMAT_N3 : DATE_TIME_FORMAT_N3_1
//             );
//             reTurnValue[`${formattedDate}`] = {
//                 bet: betCount,
//                 amount: betSum,
//                 players: uniquePlayerCount
//             };
//             if (dateList.indexOf(date) < 0) {
//                 dateList.push(date);
//             }
//         }
//
//         return reTurnValue;
//     }
//
//     for (let i = 0, len = partnerList?.length; i < len; ++i) {
//         const partner = partnerList[i];
//         const { name, betStatisticList, totalBetStatistic } = partner;
//         partner.uniqKey = `${gameName}_${name}`;
//         partner.info = innerHelper(betStatisticList);
//         const { betCount, betSum, uniquePlayerCount } = totalBetStatistic;
//         partner.total = { bet: betCount, amount: betSum, players: uniquePlayerCount };
//         delete partner.totalBetStatistic;
//         delete partner.betStatisticList;
//     }
// }

export function transformBIDataForTable(data, isDataByDay = true) {
  const infoList = [];
  const dateList = [];

  function extractDatesAndTransformList(statisticsList) {
    const returnValue = {};

    for (let i = 0, len = statisticsList?.length; i < len; ++i) {
      const {
        betCount,
        betSum,
        date,
        uniquePlayerCount,
        rake
      } = statisticsList[i];
      const formattedDate = parseDateToLocalAndFormat(
        date,
        isDataByDay ? DATE_TIME_FORMAT_N3 : DATE_TIME_FORMAT_N3_1
      );
      if (dateList.indexOf(date) < 0) {
        dateList.push(date);
      }
      returnValue[formattedDate] = {
        betSum,
        betCount,
        rake,
        uniquePlayerCount
      };
    }

    return returnValue;
  }

  for (let i = 0, len = data?.length; i < len; ++i) {
    const dataItem = data[i];
    const { betStatisticList, name } = dataItem;
    dataItem.isGame = true;
    dataItem.uniqKey = name;
    dataItem.betStatisticList = extractDatesAndTransformList(betStatisticList);
    // transformHelper(partnerList, name);
    infoList.push(dataItem);
  }
  dateList.sort((a, b) => {
    const date1 = new Date(a);
    const date2 = new Date(b);
    return date1 - date2;
  });
  for (let i = 0, len = dateList.length; i < len; ++i) {
    dateList[i] = parseDateToLocalAndFormat(
      dateList[i],
      isDataByDay ? DATE_TIME_FORMAT_N3 : DATE_TIME_FORMAT_N3_1
    );
  }

  return { infoList, dateList };
}

export function specificGamePartnersBetDataListToObject(
  data,
  isDataByDay = true
) {
  const { gameKindId, partnerBetStatisticList } = data;

  function transformList(statisticsList) {
    const returnValue = {};

    for (let i = 0, len = statisticsList?.length; i < len; ++i) {
      const {
        betCount,
        betSum,
        date,
        uniquePlayerCount,
        rake
      } = statisticsList[i];
      const formattedDate = parseDateToLocalAndFormat(
        date,
        isDataByDay ? DATE_TIME_FORMAT_N3 : DATE_TIME_FORMAT_N3_1
      );
      returnValue[formattedDate] = {
        betSum,
        betCount,
        rake,
        uniquePlayerCount
      };
    }

    return returnValue;
  }

  for (let i = 0, len = partnerBetStatisticList?.length; i < len; ++i) {
    const partner = partnerBetStatisticList[i];
    const { betStatisticList, name } = partner;
    partner.uniqKey = `${name}_${gameKindId}`;
    partner.betStatisticList = transformList(betStatisticList);
  }

  return { gameKindId, partnerBetStatisticList };
}

export function isVisibleInContainer(element, container) {
  if (element && container) {
    //     const eleLeft = element.offsetLeft;
    //     const eleRight = eleLeft + element.clientWidth;
    //
    //     const containerLeft = container.scrollLeft;
    //     const containerRight = containerLeft + container.clientWidth;
    //
    //     // The element is fully visible in the container
    //     return (
    //         eleLeft >= containerLeft && eleRight <= containerRight
    //         // Some part of the element is visible in the container
    //         // (eleTop < containerTop && containerTop < eleBottom) ||
    //         // (eleTop < containerBottom && containerBottom < eleBottom)
    //     );
    const {
      bottom,
      height,
      top,
      left,
      right,
      width
    } = element.getBoundingClientRect();
    const containerRect = container.getBoundingClientRect();

    console.log(left - right, width);
    return top <= containerRect.top
      ? containerRect.top - top <= height
      : bottom - containerRect.bottom <= height;
  }
  return false;
  // const rect = element?.getBoundingClientRect();
  // return (
  //     rect?.top >= 0 &&
  //     rect?.left >= 0 &&
  //     rect?.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
  //     rect?.right <= (window.innerWidth || document.documentElement.clientWidth)
  // );
}

export function processUserPermissions(userPermissions) {
  const permissionsBySections = {};

  for (let i = 0, len = userPermissions?.length; i < len; ++i) {
    const { section, permission } = userPermissions[i];
    if (permissionsBySections.hasOwnProperty(section)) {
      permissionsBySections[section][permission] = true;
    } else {
      permissionsBySections[section] = {};
      permissionsBySections[section][permission] = true;
    }
  }

  return permissionsBySections;
}

export function backProcessPermissions(actualPermissions) {
  const returnValue = [];

  for (const Key in actualPermissions) {
    if (Object.hasOwn(actualPermissions, Key) && actualPermissions[Key]) {
      const { permissions, isParent, key } = actualPermissions[Key];
      if (!isParent && key) {
        for (const pKey in permissions) {
          if (
            Object.hasOwn(permissions, pKey) &&
            permissions[pKey] &&
            pKey !== "All"
          ) {
            returnValue.push({ section: key, permission: pKey });
          }
        }
      }
    }
  }
  return returnValue;
}

export function getFirstValidRoutePath(routes) {
  const { getState } = store;

  const state = getState();
  const userPermissions = state.userInfo?.model?.userPermissions || {};
  for (let i = 0, len = routes?.length; i < len; ++i) {
    const { path, children, key } = routes[i];
    if (
      children?.length &&
      children?.some(child => userPermissions[child.key]?.View)
    ) {
      return getFirstValidRoutePath(children);
    } else if (key && userPermissions[key]?.View) {
      return path;
    }
  }

  return "/login";
}

export function setDefaultPermissionsForChild(existingPermissions) {
  return {
    ...ALL_FALSE,
    ...existingPermissions,
    All:
      Object.values(existingPermissions)?.filter(i => i === true)?.length ===
      ALL_PERMISSIONS_LENGTH
  };
}

export function setDefaultPermissionsForParent(children, userPermissions) {
  const childrenPermissions = children?.map(
    child => userPermissions[child?.key]
  );

  function findSpecificPermissions(permissionName) {
    return (
      childrenPermissions?.filter(i => i && i[permissionName] === true)
        ?.length === children?.length
    );
  }
  const View = findSpecificPermissions("View");
  const Add = findSpecificPermissions("Add");
  const Edit = findSpecificPermissions("Edit");
  const Export = findSpecificPermissions("Export");
  const Delete = findSpecificPermissions("Delete");

  return {
    View,
    Add,
    Edit,
    Export,
    Delete,
    All: View && Add && Edit && Export && Delete
  };
}

export function processUserOriginalPermissions(userPermissions) {
  const returnValue = {};
  for (let i = 0, len = ROUTES.length; i < len; ++i) {
    const { key, name, children } = ROUTES[i];
    if (children?.some(child => userPermissions[child.key]?.View)) {
      setDefaultPermissionsForParent(children, userPermissions);
      returnValue[name] = {
        icon: USER_ACTIONS_PERMISSIONS_ICONS[name],
        name,
        key,
        isParent: true,
        permissions: setDefaultPermissionsForParent(children, userPermissions)
      };

      for (let j = 0, len = children?.length; j < len; ++j) {
        if (userPermissions[children[j]?.key]?.View) {
          const child = children[j];
          returnValue[child?.name] = {
            name: child?.name,
            key: child?.key,
            isParent: false,
            parentName: name,
            permissions: setDefaultPermissionsForChild(
              userPermissions[child?.key]
            )
          };
        }
      }
    } else if (userPermissions[key]?.View) {
      returnValue[name] = {
        icon: USER_ACTIONS_PERMISSIONS_ICONS[name],
        name,
        key,
        isParent: false,
        permissions: setDefaultPermissionsForChild(userPermissions[key])
      };
    }
  }
  return returnValue;
}

export function processActualPermissionsWithActionType(
  userOriginalPermissions,
  actionType,
  selectedUserPermissions
) {
  const copy = JSON.parse(JSON.stringify(userOriginalPermissions));
  for (const key in copy) {
    if (Object.hasOwn(copy, key) && copy[key]?.permissions) {
      copy[key].permissions =
        actionType === "Create"
          ? ALL_FALSE
          : selectedUserPermissions[key]?.permissions ?? ALL_FALSE;
    }
  }
  return copy;
}

export function allChangeHandler(checked) {
  return checked ? ALL_TRUE : ALL_FALSE;
}

export function checkChildrenOtherPermissions(permissions, permissionName) {
  for (const key in permissions) {
    if (
      permissions.hasOwnProperty(key) &&
      key !== permissionName &&
      key !== "All"
    ) {
      if (!permissions[key]) {
        return false;
      }
    }
  }
  return true;
}

export function checkOtherChildren(children) {
  for (let i = 0, len = children?.length; i < len; ++i) {
    const permissions = children[i].permissions;
    for (const key in permissions) {
      if (permissions.hasOwnProperty(key) && !permissions[key]) {
        return false;
      }
    }
  }
  return true;
}

export function checkOtherChildrenPermission(children, permissionName) {
  for (let i = 0, len = children?.length; i < len; ++i) {
    const permission = children[i].permissions[permissionName];
    if (!permission) {
      return false;
    }
  }
  return true;
}

export function checkChildrenPermissions(children) {
  const returnValue = {};
  const len = children?.length;
  if (len) {
    const firstChild = children[0].permissions;
    for (const key in firstChild) {
      if (firstChild.hasOwnProperty(key)) {
        returnValue[key] =
          children?.filter(child => child.permissions[key] === true)?.length ===
          len;
      }
    }
  }
  return returnValue;
}

export function setCheckAllIndeterminate(permissions) {
  const truePermissionsLength = Object.values(permissions).filter(
    i => i === true
  )?.length;

  return !!(
    truePermissionsLength && truePermissionsLength < ALL_PERMISSIONS_LENGTH
  );
}

export function processFormValuesForEditRequestBody(
  values,
  isSamePermission,
  isSamePartners
) {
  const { partnerIds, userPermissions, isAllPartners } = values;
  delete values.partnerIds;
  delete values.isAllPartners;
  delete values.userPermissions;

  return {
    ...values,
    permissionsChange: isSamePermission ? null : userPermissions,
    partnersChange: isSamePartners ? null : { isAllPartners, partnerIds }
  };
}

export function checkPartners(userPartners, partners) {
  if (userPartners?.length === partners?.length) {
    if (JSON.stringify(userPartners) === JSON.stringify(partners)) {
      return true;
    }
  }
  return false;
}

export function filterValues(actualValues, allValues) {
  const returnValue = [];
  for (let i = 0, len = actualValues?.length; i < len; ++i) {
    const value = allValues?.find(val => val?.name === actualValues[i]);
    returnValue.push(value?.id);
  }
  return returnValue;
}

export function arrayEqualityCheck(first, second) {
  return (
    Array.isArray(first) &&
    Array.isArray(second) &&
    first.length === second.length &&
    first.every((val, index) => val === second[index])
  );
}

export function compareArrOfNumbers(a1, a2) {
  return (
    (a1 === a2 && typeof a1 === "null") ||
    (a1?.length === a2?.length &&
      a1.every((element, index) => element === a2[index]))
  );
}

export const filterTreeNode = (val, treeNode) => {
  return (
    !val || treeNode.title?.toLowerCase().includes(String(val).toLowerCase())
  );
};

function getBrowserSpecificProperties() {
  let visibilityChange;
  if (typeof document.hidden !== "undefined") {
    // Opera 12.10 and Firefox 18 and later support
    visibilityChange = "visibilitychange";
  } else if (typeof document.msHidden !== "undefined") {
    visibilityChange = "msvisibilitychange";
  } else if (typeof document.webkitHidden !== "undefined") {
    visibilityChange = "webkitvisibilitychange";
  }
  return visibilityChange;
}

export const VISIBILITY_CHANGE = getBrowserSpecificProperties();

export const actionIfValidLocation = ({ path, callback }) => {
  window.location.pathname.includes(path) &&
    typeof callback === "function" &&
    callback();
};

export const greaterThanZeroValidator = () => ({
  validator: async (_, val) => {
    if (!val || val <= 0) {
      return Promise.reject(new Error("Required"));
    }
  }
});

export const convertSecondsToTime = totalSeconds => {
  let time;
  let timeName;
  if (totalSeconds < 60) {
    time = totalSeconds;
    timeName = "sec.";
  } else if (totalSeconds < 3600) {
    time = totalSeconds / 60;
    timeName = "min.";
  } else {
    time = totalSeconds / 60 / 60;
    timeName = "hour";
  }

  return [time, timeName];
};
